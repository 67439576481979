import { BrowserRouter, Route, Routes } from "react-router-dom";
// import PrivateRoutes from "./routes/privateRoutes";
// import Login from "./pages/auth/login";
import LandingPage from "./pages/landingPage";
import Sejarah from "./pages/sejarah";
import VisiMisi from "./pages/visi-misi";
import About from "./pages/about"
import Kurikulum from "./pages/kurikulum";
import Psb from "./pages/informasi/psb";
import NotFound from "./pages/not-found";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/history" element={<Sejarah/>}/>
        <Route path="/visi-misi" element={<VisiMisi/>}/>
        <Route path="/about-school" element={<About/>}/>
        <Route path="/kurikulum" element={<Kurikulum/>}/>
        <Route path="/informasi-ppdb" element={<Psb/>}/>
        <Route path="*" element={<NotFound/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
